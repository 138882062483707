<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'echt-jetzt',
  primary_color: '#363636',
  secondary_color: '#F5EC00',
  header: {
    background: {
      image: `/projekt/echt-jetzt/elbworx-projekt-echt_jetzt-header-2415.webp`,
    },
    project: 'EchtJetzt',
    headline: 'Ein Sender, der neugierig macht',
    headline_low_contrast_fix: true,
    info_card: {
      description:
        'Die Entwicklung eines Namens für den neuen Sender, der neugierig macht, modern und memorierbar ist, auf das Programm verweist und keine religiösen Barrieren aufbaut.',
      client: 'BibelTV',
      roles: ['Logoentwicklung', 'Motiondesign', 'Webdesign', 'Kampagnendesign'],
      project_duration: 'März 2022 bis August 2022',
      custom_button_class: 'bg-[#F79100]',
      project_url: 'https://echtjetzt.tv',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Die Herausforderung für BibelTV</h3>
      <p>
        BibelTV ist ein Fernsehsender, der über Satellit, Kabel und OnlineStream linear und rund um
        die Uhr Programm ausstrahlt, welches die christliche Botschaft zum Thema hat. Neben dem
        Hauptsender hat Bibel TV noch zwei Spartensender, die Online verfügbar sind. Bibel TV
        Impulse und Musik. Die Vision von Bibel TV ist es Menschen, einen Schritt näher zum Gott der
        Bibel zu führen. Der Musik-Kanal war dazu gedacht, Menschen im Alltag eine Art visuelle
        Playlist zu geben, mit der sie in ihrem Alltag mit Musik und durch Musik Gott erleben
        konnten. Da dieser Kanal zuletzt kaum noch genutzt wurde kam die Idee auf den Sendeplatz für
        ein neues Format freizugeben.
      </p>
      <p>
        Mit seinem vielfältigen Programm erreicht Bibel TV bereits jetzt 30%, das sind 80.000
        Zuschauer pro Tag, die keine Bindung zu einer Kirche haben. Dennoch halten sich mit der
        Marke Bibel TV immer noch hartnäckig diverse Vorurteile und ein angestaubtes Image. Eins der
        Ziele der Bibel TV Stiftung gGmbH ist es, noch mehr Menschen mit der guten Nachricht zu
        erreichen, die damit noch keine Berührungspunkte hatten.
      </p>
      <p>
        Wann werden wir offen für neue Impulse die unser eigenes Leben betreffen? Wenn Menschen von
        Erlebnissen berichten, die echt sind und in uns den Wunsch wecken, Ähnliches zu erleben.
      </p>

      <h3>Die Lösung von BibelTV</h3>
      <p>
        Die Entwicklung eines Senders, der rund um die Uhr Geschichten von Menschen ausstrahlt, die
        etwas mit Gott erlebt haben, ist der Ansatz. Dieser Sender soll einem möglichst breiten und
        vor allem nicht-gläubigen Publikum zugänglich gemacht werden. Daher soll er namentlich
        unbelastet und ohne Barrieren sein.
      </p>
    </template>

    <Padding>
      <Grid class="items-start">
        <div class="col-span-5 col-start-1 flex flex-col gap-8">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-echt_jetzt-notes-717.webp`"
          />
          <Image
            class="w-full"
            :src="`${projectPath}elbworx-projekt-echt_jetzt-moodboard-1920.webp`"
          />
        </div>
        <div class="col-span-6 col-end-13">
          <Text>
            <h3>Unsere Vorgehensweise</h3>

            <p>
              Um dieser Herausforderung angemessen zu begegnen, haben wir einen Fragenkatalog für
              unseren Kunden entwickelt, um mehr über die Zielgruppe herauszufinden. Wie diese
              tickt, was sie beschäftigt und mit welchen alltäglichen Problemen sie zu kämpfen hat.
              Dem gegenüber steht das Programm des Kanals und welche Lösungsangebote es der
              Zielgruppe macht.
              <br />
              Man glaubt es kaum, aber selbst in diesem Bereich gibt es Mitbewerber oder zumindest
              Anbieter mit ähnlichen Anliegen. Diese haben wir benannt und analysiert. Mit dem
              Ergebnis unserer Analyse waren wir nun gewappnet, in die kreative Namensfindung und
              -entwicklung zu gehen.
            </p>
            <h3>Die Optik</h3>
            <p>Wie kommen wir dazu, dass es EchtJetzt nun so aussieht?</p>
            <p>
              Wir gehen Schrittweise voran. Wir beschäftigen intensiv mit der vorher definierten
              Zielgruppe, recherchieren was und womit sie sich beschäftigt, welche Dinge ihr gefällt
              und welche nicht. Daraus entwickeln wir mehrere Stimmungsbilder oder Moodboards. Das
              sind sehr oft Collagen aus Bilder, Farbschemen, Schriftarten und grafischen Elementen,
              wie Icons, charakteristischen Formen, Mustern oder Elementen.
            </p>
            <p>
              Mit diesen Bildern gehen wir in den Austausch mit dem Kunden. Manche Einschätzungen
              und Annahmen werden dabei korrigiert und in die richtige Tonalität gebracht.
            </p>
            <p>Der Prozess hat längst begonnen, aber wird Kreation konkret.</p>
            <p>
              Wir entwickeln Logo, Farben, Schriften, Formen und Elemente, die der Zielgruppe
              entsprechen und die Botschaften der Marke unterstützen. Die Teams aus Graphic, Web,
              Motion Design und Entwicklung arbeiten Hand in Hand, wenden das Basisdesign auf die
              benötigten Medien an und entwickeln es entsprechend der Anwendungsfälle weiter.
            </p>
          </Text>
        </div>
      </Grid>
    </Padding>
    <div class="flex flex-col">
      <Tilt class="mt-10 flex justify-center bg-primary pb-tilt">
        <Image
          class="object-contain"
          :src="`${projectPath}elbworx-projekt-echt_jetzt-logo_entwicklung-3840.webp`"
        />
      </Tilt>
      <Tilt contain class="-mt-tilt !pb-0">
        <Grid>
          <Tilt contain class="col-span-5 -mt-tilt bg-secondary">
            <Padding class="py-12">
              <Text>
                <h3>Das ist der Name</h3>
                <h4>EchtJetzt</h4>

                <p>
                  Authentizität hat Hochkonjunktur. Auch in Krisenzeiten ist unser größter Wunsch,
                  die Wahrheit herauszufinden, um die richtige Entscheidung zu treffen.
                  <br />
                  Wir sehnen uns nach echten, authentischen Menschen, die uns wahre Geschichten
                  erzählen, die so unglaublich und unfassbar scheinen, dass wir sie im ersten Moment
                  gar nicht glauben können – aber wollen. Dieser spontane Ausruf kann als
                  (rethorische) Frage oder Aussage konnotiert werden. Er enthält das „echt sein“ und
                  „gegenwärtig sein“ und steht damit für das Programm und die Geschichten, die von
                  realen Erlebnissen berichten und das 24 Stunden an 7 Tagen die Woche.
                </p>
              </Text>
            </Padding>
          </Tilt>
          <div class="col-span-7 -mt-tilt">
            <Image
              class="w-full object-cover"
              :src="`${projectPath}elbworx-projekt-echt_jetzt-bildmarke-1920.webp`"
            />
          </div>
        </Grid>
      </Tilt>
      <Tilt contain class="-mt-tilt !pt-0">
        <Grid>
          <div class="col-span-7 pt-16">
            <Image
              class="w-full object-cover"
              :src="`${projectPath}elbworx-projekt-echt_jetzt-claim-1920.webp`"
            />
          </div>
          <Tilt contain class="col-span-5 bg-secondary">
            <Padding class="pb-8 pt-12">
              <Text>
                <h3>Der Claim</h3>

                <p>
                  EchtJetzt zeigt Geschichten, bei denen echte und ganz normale Menschen von etwas
                  berichten und bezeugen, was sie mit Gott erlebt haben oder wie und wo er in ihr
                  Leben eingegriffen hat. Der Claim lädt ein, aktiv zu werden, der Geschichte auf
                  den Grund zu gehen und zu prüfen, ob an einer unglaublichen oder zumindest
                  faszinierenden Geschichte wirklich etwas Wahres dran ist. Der Claim setzt nichts
                  voraus und lässt die Geschichten selbst unbewertet.
                </p>
                <h4>Weil alles möglich ist</h4>
              </Text>
            </Padding>
          </Tilt>
        </Grid>
      </Tilt>
    </div>
    <ImageSwiper
      slide-button-class="bg-secondary"
      :space-between="100"
      :images="[
        {
          url: `${projectPath}elbworx-projekt-echt_jetzt-slider1-1440.webp`,
          alt: 'allein gelassen',
        },
        {
          url: `${projectPath}elbworx-projekt-echt_jetzt-slider2-1440.webp`,
          alt: 'echt jetzt',
        },
        {
          url: `${projectPath}elbworx-projekt-echt_jetzt-slider3-1440.webp`,
          alt: 'weil alles möglich ist',
        },
      ]"
    />
    <Padding class="mt-12 flex justify-center">
      <Text>
        <h3>Social Media Posts und Reels</h3>
        <p>
          Ein weiterer Schritt um EchtJetzt mehr Reichweite zu verschaffen, war es eine Social Media
          Kampagne zu entwickeln. Anhand unserer Guidelines entstanden Instagram Posts und
          Karussell-Post sowie eine Auswahl an Reels. Um den Betrachter neugierig zu machen
          unterlegten wir die Bilder mit Fragestellungen und Aussagen um gespannt darauf zu machen
          welche Inhalte die Streaming Plattform anbietet.
        </p>
      </Text>
    </Padding>
    <Grid class="mt-12 items-end">
      <div class="col-span-5">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-echt_jetzt-framework-1920.webp`"
        />
      </div>
      <div class="col-span-5 col-start-7">
        <Padding class="md:contents">
          <Text>
            <h3>Webdesign</h3>
            <p>
              Gemeinsam mit dem Entwicklungspartner falconDev IT GmbH, haben wir die Anforderungen
              an die Website analaysiert. Die nötige Funktionalität sowie die Daten wurden modular
              in einem Wireframe-Modell strukturiert, welches die Basis für das Screendesign
              bildete. Parallel konnten bereits APIs geschrieben werden, um die Sendungen und
              Programminhalte mit dem Hauptsender abzugleichen.
            </p>
          </Text>
        </Padding>
      </div>
    </Grid>
    <Tilt contain class="relative mt-12 bg-primary">
      <BackgroundMedia
        :background-image="`${projectPath}elbworx-projekt-echt_jetzt-macbook_background-3840.webp`"
      />
      <Padding class="py-12">
        <Device
          :element="{
            device_type: 'desktop',
            frame_color: 'white',
            image_url: `${projectPath}elbworx-projekt-echt_jetzt-website-2560.webp`,
          }"
        />
      </Padding>
    </Tilt>
    <Padding>
      <div class="flex flex-wrap justify-center gap-14 sm:flex-nowrap lg:mx-52">
        <div class="w-2/3">
          <Image
            class="w-full object-cover sm:mt-28"
            :src="`${projectPath}elbworx-projekt-echt_jetzt-ipad-1280.webp`"
          />
        </div>
        <div class="w-1/3">
          <Image
            class="w-full object-contain"
            :src="`${projectPath}elbworx-projekt-echt_jetzt-mobile-960.webp`"
          />
        </div>
      </div>
    </Padding>
    <Tilt class="relative mt-20 h-[clamp(20rem,100vw,70vh)]">
      <BackgroundMedia
        :background-image="`${projectPath}elbworx-projekt-echt_jetzt-train-1920.webp`"
        :background-video="`${projectPath}elbworx-echtjetzt-1080.mp4`"
      />
    </Tilt>
    <Padding class="mt-8 flex justify-center">
      <Text>
        <h3>Station Calls</h3>
        <p>
          Wir entwickelten mehrere kurzer Einblender die zischen den Programmsendungen abgespielt
          werden sollen und aufzeigen das man sich auf dem Sender EchtJetzt befindet. Er ist wie
          eine kleine Signatur. Wir recherchierten passende Videomaterial, entwickelten die
          Audiospur und animierten Logo und Claim so, dass es die frische und Andersartigkeit von
          EchtJetzt widerspiegelt.
        </p>
      </Text>
    </Padding>
  </Project>
</template>
